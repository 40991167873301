<template>
  <div class="slide-three-page">
    <div class="center-view">
      <swiper
        ref="swiperRef"
        :slides-per-view="1"
        :space-between="10"
        :modules="modules"
        :autoplay="{
          delay: 3000,
          disableOnInteraction: false,
        }"
        @swiper="initSwiper"
        @activeIndexChange="activeIndexChange"
        >
        <div class="custom-pagination">
          <div class="page-view">
            <span @click="changeSw(i-1)" :class="`pag-item ${active == (i - 1) ? 'pag-item-active' : ''}`" v-for="i in 7" :key="i"></span>
          </div>
          <div class="more-text" @click="showMask">
            <p>{{ $t('productServices.moreText') }}</p>
            <img class="arrow-img" :src="`${$COS}/img_arrow.png`" alt="">
          </div>
        </div>
        <swiper-slide>
          <slideProductOne />
        </swiper-slide>
        <swiper-slide>
          <slideProductTwo />
        </swiper-slide>
        <swiper-slide>
          <slideProductThree />
        </swiper-slide>
        <swiper-slide>
          <slideProductFive />
        </swiper-slide>
        <swiper-slide>
          <slideProductSix />
        </swiper-slide>
        <swiper-slide>
          <slideProductSeven />
        </swiper-slide>
        <swiper-slide>
          <slideProductFour />
        </swiper-slide>
      </swiper>
    </div>
    <div class="left-view-bg">
      <div class="left-view">
        <div class="tip">
          <p class="border"></p>
          <p class="title">{{$t("productServices.title")}}</p>
        </div>
        <img class="menu-img" :src="`${$COS}/menu_blue.png`">
      </div>
    </div>
    <div v-if="isShowMask" class="mask-view-bg">
      <div class="mask-view">
        <div class="select-view">
          <div
            :class="`item-view ${active == index ? 'disable-item': ''}`"
            v-for="(item,index) in selectList"
            @click="handleSelect(index)"
            :key="index">
            <img :src="`${$COS}/icon_hover@3x.png`" v-if="active != index" class="hover-img" alt="">
            <p class="title">{{ item.title }}</p>
            <!-- <p class="desc">{{ item.desc }}</p> -->
          </div>
        </div>
        <div class="close-view">
          <img @click="isShowMask = false" class="close-img" :src="`${$COS}/img_close.png`" alt="">
        </div>
      </div>
    </div>
  </div>
</template>


<script >
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import slideProductOne from './product/slideProductOne'
import slideProductTwo from './product/slideProductTwo'
import slideProductThree from './product/slideProductThree'
import slideProductFour from './product/slideProductFour'
import slideProductFive from './product/slideProductFive'
import slideProductSix from './product/slideProductSix'
import slideProductSeven from './product/slideProductSeven'
import { Autoplay  } from 'swiper/modules'

export default {
  components: {
    Swiper,
    SwiperSlide,
    slideProductOne,
    slideProductTwo,
    slideProductThree,
    slideProductFour,
    slideProductFive,
    slideProductSix,
    slideProductSeven
  },
  data () {
    return {
      active: 0,
      modules: [Autoplay],
      isShowMask: false,
      swiper: null
    }
  },
  computed: {
    selectList () {
      return [
        {
          title: this.$t('productServices.card1.text1') + this.$t('productServices.card1.text2') + this.$t('productServices.card1.text3'),
          desc: this.$t('productServices.card1.desc')
        },
        {
          title: this.$t('productServices.card2.text1') + this.$t('productServices.card2.text2'),
          desc: this.$t('productServices.card2.desc')
        },
        {
          title: this.$t('productServices.card3.text1') + this.$t('productServices.card3.text2') + this.$t('productServices.card3.text3'),
          desc: this.$t('productServices.card3.desc')
        },
        {
          title: this.$t('productServices.card5.text1') + this.$t('productServices.card5.text2')+ this.$t('productServices.card5.text3'),
          desc: this.$t('productServices.card4.desc')
        },
        {
          title: this.$t('productServices.card6.text1') + this.$t('productServices.card6.text2')+ this.$t('productServices.card6.text3'),
          desc: this.$t('productServices.card4.desc')
        },
        {
          title: this.$t('productServices.card7.text1') + this.$t('productServices.card7.text2'),
          desc: this.$t('productServices.card4.desc')
        },
        {
          title: this.$t('productServices.card4.text1') + this.$t('productServices.card4.text2'),
          desc: this.$t('productServices.card4.desc')
        },
      ]
    },
  },
  methods: {
    showMask () {
      this.swiper.autoplay.stop()
      this.isShowMask = true
    },
    initSwiper (sw) {
      this.swiper = sw
    },
    activeIndexChange (sw) {
      this.active = sw.activeIndex
    },
    changeSw (i) {
      this.swiper.slideTo(i)
    },
    handleSelect (i) {
      if (i === this.active) {
        return
      }
      this.isShowMask = false
      this.swiper.slideTo(i)
      this.swiper.autoplay.start()
    }
  }
}
</script>

<style scoped lang="scss">
.slide-three-page{
  width: 100%;
  height: 100%;
  position: relative;
  background-image: linear-gradient(180deg, #FFFFFF 47%, #E1EEFF 100%);
  display: flex;
  .center-view{
    flex: 1;
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 50px;
    .swiper {
      width: 1170px;
      .custom-pagination{
        position: absolute;
        right: 0;
        top: 0;
        z-index: 10;
        text-align: right;
        .page-view{
          height: 20px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .pag-item{
            width: 12px;
            height: 12px;
            background: #2B9CFF;
            opacity: 0.12;
            border-radius: 50%;
            margin-left: 10px;
            cursor: pointer;
          }
          .pag-item-active{
            opacity: 1;
            background: #1F75FF;
          }
        }
        .more-text{
          margin-top: 14px;
          font-size: 16px;
          color: #5A68A1;
          line-height: 40px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .arrow-img{
            height: 18px;
            margin-left: 5px;
          }
        }
      }
    }
    .swiper-slide {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .left-view-bg{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    .left-view{
      position: relative;
      .tip{
        position: absolute;
        left: 95px;
        top: 200px;
        font-size: 20px;
        color: #2E3A6B;
        line-height: 40px;
        height: 40px;
        font-weight: bolder;
        display: flex;
        align-items: center;
        white-space: nowrap;
        .border{
          width: 12px;
          height: 12px;
          background-color: #2E3A6B;
          border-radius: 50%;
          margin-right: 20px;
        }
        .title{
          text-align: left;
          width: 120px;
          height: 40px;
          white-space: break-spaces;
        }
      }
      .menu-img{
        height: 500px;
        display: block;
      }
    }
  }
  .mask-view-bg{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    display: flex;
    .mask-view{
      position: relative;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      &::before{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: url('https://qtiao-web-1258997378.cos.ap-guangzhou.myqcloud.com/taoyuanintl/pc/bg_mask.png') no-repeat;
        transform: rotate(180deg);
      }
      .select-view{
        display: flex;
        flex-direction: column;
        text-align: right;
        .item-view{
          margin-top: 80px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .title{
            font-size: 32px;
            color: #FFFFFF;
            line-height: 40px;
            font-weight: bolder;
          }
          .desc{
            font-size: 16px;
            color: #FFFFFF;
            line-height: 40px;
          }
          .hover-img{
            width:28px;
            height:28px;
            margin-right: 16px;
            display: none;
          }
          &.item-view:first-child{
            margin-top: 0;
          }
          &.item-view:hover{
            .hover-img{
              display: block;
            }
          }
        }
        .disable-item{
          opacity: 0.6;
        }
      }
      .close-view{
        margin-left:80px ;
        margin-right: 180px;
        padding: 16px;
        .close-img{
          cursor: pointer;
          width: 40px;
          height: 40px;
        }
      }
    }


  }
}
</style>
