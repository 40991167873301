<template>
  <div class="page">
    <topView :slideIndex="activeIndex" />
    <swiper
      direction="vertical"
      :slides-per-view="1"
      :space-between="0"
      :lazy="true"
      :mousewheel="true"
      :modules="modules"
      @activeIndexChange="activeIndexChange"
    >
      <swiper-slide><homeSlide /></swiper-slide>
      <swiper-slide><companySlide loading="lazy"/></swiper-slide>
      <swiper-slide><productSlide loading="lazy"/></swiper-slide>
      <swiper-slide><caseSlide loading="lazy" :activeIndex="activeIndex" /></swiper-slide>
      <swiper-slide><teamSlide loading="lazy" /></swiper-slide>
      <swiper-slide><contactSlide loading="lazy" /></swiper-slide>
    </swiper>
  </div>
</template>

<script setup>
import {ref,onMounted} from 'vue'
import {useRoute}  from 'vue-router'
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Mousewheel } from 'swiper/modules';
import 'swiper/css';
import homeSlide from '@/components/homeSlide'
import companySlide from '@/components/companySlide'
import productSlide from '@/components/productSlide'
import caseSlide from '@/components/caseSlide'
import teamSlide from '@/components/teamSlide'
import contactSlide from '@/components/contactSlide'
import topView from '@/components/topView'

import { useI18n } from "vue-i18n";
const { locale, t } = useI18n();

const route = useRoute()

const modules = [Mousewheel]

const activeIndex = ref(0)

onMounted(()=> {
  locale.value = route.params.language || 'zh'
  document.title = t('title')
})

const activeIndexChange = (sw) => {
  activeIndex.value = sw.activeIndex
}

</script>

<style scoped lang="scss">
.page{
  position: relative;
  .swiper {
    width: 100%;
    height: 100vh;
  }
  .swiper-slide {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
