<template>
  <div class="product-slide-one">
    <div class="top-view">
      <p class="title">
        <span>{{ $t('productServices.card3.text1') }}</span>
        <span class="stand">{{ $t('productServices.card3.text2') }}</span>
        <span>{{ $t('productServices.card3.text3') }}</span>
      </p>
      <p class="desc">{{ $t('productServices.card3.desc') }}</p>
      <img class="project-img" loading="lazy" v-if="locale == 'zh'" :src="`${$COS}/project_jx.png`" alt="">
      <img class="project-img" loading="lazy" v-if="locale == 'en'" :src="`${$COS}/en_project_jx.png`" alt="">
    </div>
    <div class="flow-view">
      <div class="flow-text">{{$t('productServices.tips')}}</div>
      <img class="progress-img" loading="lazy" :src="`${$COS}/img_progress2.png`">
      <div class="progress-view">
        <div class="flow-item" v-for="item in productProgress3" :key="item">
          <p :class="`item-title ${locale}-item-title`">{{item.label}}</p>
          <p class="item-desc">{{item.tip}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
const { locale } = useI18n();
import { productProgress3 }  from '@/utils/enumeration'


</script>


<style scoped lang="scss">
@import './product.scss';
.item-desc{
  padding-right: 70px;
}

</style>
