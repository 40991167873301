
// 定义中文语言包对象
export default {
  title:'桃原国际',
  video:{
    title: '首页',
    desc1:' 深圳市桃原国际管理咨询有限公司是香港大型国际移民安居公司KITO旗下企业，',
    desc1_1: '以“聚焦香港，引领人才出海。线上服务，高效连接未来。”的理念',
    desc2: '为个人用户与合作渠道提供最专业的香港身份一站式综合服务：',
    desc2_2: '',
    desc3: '如优秀人才入境计划、高端人才通行证计划、香港进修计划、输入内地人才计划等。',
    desc4: '滚动鼠标了解更多'

  },
  companyAbout: {
    title: '公司介绍',
    desc: '桃原国际在深圳、香港、厦门和新加坡均设有办公室，现有超过70名的专业员工。核心成员均来自国际移民和留学教育头部企业，其中50%以上拥有香港身份，硕士学历和留学人员占比超80%',
  },
  // 产品服务
  productServices:{
    title: '产品服务',
    moreText: '更多计划',
    tips: '办理流程',
    card1:{
      text1:'香港',
      text2:'优秀人才',
      text3:'入境计划',

      desc:'一人申请，全家可获香港身份',

      tool1:'申请面向全球',
      tool2:'全家受益',
      tool3:'黄金跳板',
      tool4:'办理周期短',
      tool5:'社会福利好',
      tool6:'优质教育',
      tool7:'免签国家多',
      tool8:'高效快速',

      progress1Label:'签约办理协议',
      progress1Tip:'(1天)',
      progress1Desc1:'签约香港身份办理协议',
      progress1Desc2:'缴纳服务费',

      progress2Label:'准备资料递交',
      progress2Tip:'(约1-2个月)',
      progress2Desc1:'填写个人资讯，方案定位',
      progress2Desc2:'文书确认与定稿',
      progress2Desc3:'提供并整理全套文件递交入境处',

      progress3Label:'等待审批',
      progress3Tip:'(约6个月)',
      progress3Desc1:'下发专属档案号',
      progress3Desc2:'等待香港入境处的筛选结果',

      progress4Label:'获批、取得签证',
      progress4Tip:'审批通过后',
      progress4Desc1:'获得香港签证',
      progress4Desc2:'和正式批准信',

      progress5Label:'办理香港身份证',
      progress5Tip:'(约1个月)',
      progress5Desc1:'办理逗留D签证',
      progress5Desc2:'入境香港激活签证',
      progress5Desc3:'办理香港身份证',

      progress6Label:'续签',
      progress6Tip:'(提前半年准备，每次约1个月)',
      progress6Desc1:'以3+3+2的形式续签',
      progress6Desc2:'在签证到期前一个月',
      progress6Desc3:'递交续签申请',

      progress7Label:'永居',
      progress7Tip:'(约1-2个月)',
      progress7Desc1:'准备永居资料递交入境处',
      progress7Desc2:'通过后办理永久居民身份证与香港护照',
    },
    card2:{
      text1:'高端人才',
      text2:'通行证计划',

      desc:'2年签证，获批速度快',

      tool1:'A类申请人',
      tool2:'过去1年年薪250万港元',

      tool3:'B类申请人',
      tool4:'全球百强大学学士学位，过去五年有三年工作经验',

      tool5:'C类申请人',
      tool6:'全球百强大学学士学位，工作经验少于3年',
      tool7:'(每年限额1万)',

      progress1Label:'签约办理协议',
      progress1Tip:'(1天)',

      progress2Label:'准备资料递交',
      progress2Tip:'(约1周)',

      progress3Label:'等待审批',
      progress3Tip:'(约4-6周)',

      progress4Label:'获批、取得签证',
      progress4Tip:'审批通过后',

      progress5Label:'办理香港身份证',
      progress5Tip:'(约1个月)',

      progress6Label:'续签',
      progress6Tip:'(续签2次，提前1年准备，每次约1个月)',

      progress7Label:'永居',
      progress7Tip:'(约1-2个月)',
    },
    card3:{
      text1:'香港',
      text2:'进修',
      text3:'计划',

      desc:'量身定制教育进修方案，全程跟进申请进度',

      tool1Title:'私人订制',
      tool1Desc1:'“全球视野，量身定制”理念',
      tool1Desc2:'为客户定制个性化方案',

      tool2Title:'顶级顾问',
      tool2Desc:'顶级名校申请与就读经验的专业人士组成团队',

      tool3Title:'优质资源',
      tool3Desc1:'顶尖院校合作',
      tool3Desc2:'连结50+细分渠道自身供应商',

      tool4Title:'全链路服务',
      tool4Desc1:'全链路进修服务',
      tool4Desc2:'包含选校、申请、签证、续签准备等',

      progress1Label:'需求分析',
      progress1Desc:'专业顾问与客户进行深入沟通，了解客户的背景、需求和目标，帮助客户确定最佳的进修方案',

      progress2Label:'选校申请',
      progress2Desc:'导师将根据客户的需求和背景，为客户推荐最适合的学校，并协助客户完成材料申请的准备、申请提交等流程',

      progress3Label:'文书撰写',
      progress3Desc:'专业文书团队将为客户打造优质的个人陈述、推荐信、简历等申请文书，以提高客户的录取率',

      progress4Label:'面试辅导',
      progress4Desc:'专业导师将为客户提供面试辅导，包括类比面试、针对性问题的解答等，以帮助客户克服面试紧张，提高表现水平',

      progress5Label:'后续服务',
      progress5Desc:'协助客户办理签证、出行安排，以确保客户的进修生活得到全方位的保障和支援',
    },
    card4:{
      text1:'美国',
      text2:'EB1A和NIW',

      desc:'EB1A 3-4年与NIW 5-6年，一步到位美国绿卡',

      tool1:'EB1A杰出人才移民项目',
      tool2:'在科学、艺术、教育、商业、体育五⼤领域享有国家级或国际级的声誉与成就的杰出人才，且其成就和贡献在领域得到⼴泛认可，是该领域的顶尖⼈物。要求申请人在奖项、协会、专访、评审、原创性、论文、展览、重要职位、高薪酬、商业成就等10项要求中满足3项。',

      tool3:'NIW 高技术移民(国家利益豁免移民）',
      tool4:'高等学位/特殊技能，且需满足：',
      tool5:'1. 所从事的专业领域必须具有实质性价值，可为美国带来重大利益;',
      tool6:'2. 具备出色贡献和成就且处于推动其领域发展的有利位置;',
      tool7:'3. 申请人必须证明：豁免劳工证要求是有益于美国国家利益。',

      progress1Label:'签约办理协议',
      progress1Tip:'(1天)',

      progress2Label:'准备资料递交',
      progress2Tip:'(约4-6个月)',

      progress3Label:'等待审批过程',
      progress3Tip:'(约1年-1年半)',

      progress4Label:'等待排期',
      progress4Tip:'排期时间动态变化',

      progress5Label:'NVC国家签证中心',
      progress5Tip:'(约1-12个月)',

      progress6Label:'体检面试',
      progress6Tip:'(约1个月)',

      progress7Label:'登陆美国，领取绿卡',
      progress7Tip:'(约1-3个月)',
    },
    card5:{

      text1:'企业家',
      text2:'专才',
      text3:'计划',

      desc:'企业家专才是专才计划的一种特殊形式',

      progress1Label:'需求分析',
      progress1Tip:'深入了解客户背景、学历、工作等实际情况精准切入客户的需求和目标。',

      progress2Label:'方案定制',
      progress2Tip:'根据客户的需求和目标，以成功获批为原则，为客户制定高灵活、可执行的个性化企业家专才计划方案。',

      progress3Label:'执行落地',
      progress3Tip:'执行公司成立方案：成立香港公司、聘请本地员工、处理MPF、提供银行业务、提供秘书公司服务。',

      progress4Label:'递交申请',
      progress4Tip:'专业文书团队将为客户打造优质的个人陈述、推荐信等申请文书，以提高客户的获批率',

      progress5Label:'后续服务',
      progress5Tip:'累计上干客户的操作经验，运用针对性、具策略性的补件手段，精准回复官方疑问，为审批过程保驾护航'

    },
    card6:{

      text1:'护士',
      text2:'专才',
      text3:'计划',

      desc:'内地护士通过专才计划申请香港身份，赴港就业',

      progress1Label:'投递简历',
      progress1Tip:'准备简历，定向投递',

      progress2Label:'初面',
      progress2Tip:'人力机构线上初面',

      progress3Label:'线下终面',
      progress3Tip:'香港雇主线下终面',

      progress4Label:'录取',
      progress4Tip:'通知录取，签署合同',

      progress5Label:'办理签证',
      progress5Tip:'递交专才签证申请',

      progress6Label:'就职',
      progress6Tip:'赴港就业，顺利入职',

      progress7Label:'护士进阶',
      progress7Tip:'培训考证、转为注册护士(HKRN)'

    },
    card7:{

      text1:'香港身份',
      text2:'续签',

      desc:'现有签证到期前向香港入境事务处提交申请签证延期',

      progress1Label:'需求分析',
      progress1Tip:'专业顾问与客户进行深入沟通，了解客户的背景、需求和目标，帮助客户确定最佳的续签方案',

      progress2Label:'续签规划',
      progress2Tip:'根据续签要求和客户实际情况，定制详尽的居留计划和工作规划',

      progress3Label:'过程管理',
      progress3Tip:'对接香港本地资源，提供生活、就业辅助，跟进规划实施情况',

      progress4Label:'申请准备',
      progress4Tip:'收集、整理续签所需文件材料：赴港记录、工作证明等 ，撰写必要的申请说明文书',

      progress5Label:'续签获批',
      progress5Tip:'协助客户递交续签申请。跟进申请情况，运用针对性、策略性的补件手段，为续签保驾护航',

    },
  },
  // 成功案例
  successCase:{
    title: '成功案例',
    pageTitle: '客户获批案例',
    case1:{
      title:'优才成功获批案例',
      type: '优才',
      name:'S女士：35岁｜学士',
      cycle:'获批周期：9个月',
      grade:'评分：160分',
      desc:'S女士毕业于全球QS 50强名校，具备海归背景，自毕业后在知名进出口企业工作13年，专注国际贸易工作，一路晋升至销售总监。通过与其沟通，在文书撰写中着重强调其国际背景、名企优势、资历优势，并结合香港自身贸易行业特点和发展需求，量身打造极具吸引力的人设，最终成功帮助S女士于2023年获批。'
    },
    case2:{
      title:'优才成功获批案例',
      type: '高才',
      name:'Z先生：31岁｜硕士',
      cycle:'获批周期：4个月',
      grade:'评分：145分',
      desc:'Z先生为国内双非院校人力资源管理专业学士和海归硕士（全球QS 50强），曾就职于多家知名互联网公司，拥有供应链管理、信息化平台系统建设等经验。经过详细沟通，结合香港当前发展的需求，我们重点突出了其名企、名校、国际化背景优势，着重强调其供应链管理方面的强大能力，最终成功帮助Z先生于2023年获批。'
    },
    case3:{
      title:'高才A类成功获批案例',
      type: '进修',
      name:'P先生：40岁',
      cycle:'获批周期：2个月',
      grade:'',
      desc:'P先生目前是某数据技术公司CEO，并拥有公司分红权，在2022年9月至2023年9月收入近500万人民币，缴税近160万人民币。我们团队协助其详尽整理了工作证明（公司劳动合同、在职证明、社保记录）、收入与纳税证明（收入明细、纳税记录、收入纳税汇总明细）为主的全部证明材料，最终成功帮助P先生于2023年10月底获批。'
    },
    case4:{
      title:'进修成功获批案例',
      type: '专才',
      name:'C先生：26岁｜学士',
      cycle:'获批周期：1个月',
      grade:'',
      desc:'C先生本科毕业于内地双非院校管理专业，拥有一定金融投资经验，目前接手家族企业，单身未婚。在与我们接触前曾申请优才失败，经过沟通最终选择香港珠海学院工商管理（创业管理）专业为申请方向，我们贴合学院招生需求，重点强调和包装了其在家族企业中的成熟商业管理经验和成就，并提供了定制化的面试辅导服务，最终成功帮助C先生于2023年11月获批。'
    },
    case5:{
      title:'进修成功获批案例',
      type:'续签/成就计分/受养人',
      name:'C先生：26岁｜学士',
      cycle:'获批周期：1个月',
      grade:'',
      desc:'C先生本科毕业于内地双非院校管理专业，拥有一定金融投资经验，目前接手家族企业，单身未婚。在与我们接触前曾申请优才失败，经过沟通最终选择香港珠海学院工商管理（创业管理）专业为申请方向，我们贴合学院招生需求，重点强调和包装了其在家族企业中的成熟商业管理经验和成就，并提供了定制化的面试辅导服务，最终成功帮助C先生于2023年11月获批。'
    }
  },
  // 用户好评
  userReviews:{
    title: '客户评价',
    changeText: '换一换',
    review1:{
      name:'陈先生',
      position:'某软件公司高级工程师',
      remark:'小潘老师辛苦啦，为您的敬业精神及超高质量的计划书点赞，抱歉昨晚手机没电自动关机了，刚看到。 非常感谢，整个人重点凸显，熠熠生辉，我稍微调整了几个词，麻烦您看下。'
    },
    review2:{
      name:'康女士',
      position:'大型超市地区负责人',
      remark:'小潘老师，看了您写的文书真的好棒，可比我们自己写的专业太多了。我觉得写的很好！ 对我人生都有了启发。'
    },
    review3:{
      name:'李女士',
      position:'海归硕士，大型互联网公司HR',
      remark:'我刚看完，哇唔，写的真的很赞啊！我觉得没什么可以改的了这个，果然实力超群。大家看了都在夸赞写的好呀，我感觉好有希望。我一会去开始准备我的英文自述然后应该明天发给小潘大神看。'
    },
    review4:{
      name:'王先生',
      position:'建筑设计工程师',
      remark:'辛苦啦，周末还出稿子，给你们的敬业点赞。 刚看完了，计划书整体思路清晰 语言畅达 感谢潘老师。 我这边没什么需要修改的了！'
    },
    review5:{
      name:'罗先生',
      position:'大型保险集团管理人员',
      remark:'潘老师效率好高呀，逻辑清晰，思路缜密。 在平安银行的工作内容我选取了金融方向，就不往市场方向写了。 麻烦在这个基础上再帮我优化一下。'
    },
    review6:{
      name:'王女士',
      position:'物流公司主管',
      remark:'“看着你们的文章，我都有点脸红了 [破涕为笑]”，有咱们的文书加持，不管最后结果如何，我还是特别有信心，我会认真准备的。'
    },
    review7:{
      name:'任小姐',
      position:'医疗行业高级人员',
      remark:'太厉害了，连我们公司的产品线都做了这么详细的调研。 很多连我们自己的员工也不一定能够这么精准地评价出我们和普通抗衰老行业的不同，你们团队果然是专业的！'
    },
    review8:{
      name:'李先生',
      position:'大厂游戏制作人',
      remark:'我看完了，你们团队真的太专业了。 能把国内游戏市场和国际出海游戏市场分析得这么透彻，感觉你们小潘老师是不是都做过市场调研类得工作呀！'
    },
  },
  // 管理团队
  manageTeam:{
    title: '管理团队',
    team1:{
      name:'Hyan 杨国江先生',
      position:'创始人',
      desc1:'5年香港金融集团公司高管经验',
      desc2:'5年移民公司高管经验',
      desc3:'金管局技术发展顾问',
      desc4:'多家上市公司海外投资顾问',
      desc5:'KITO董事合伙人',
      desc6:'光合财富家族办公室创始人'
    },
    team4:{
      name:'Marc 刘宇斌先生',
      position:'合伙人',
      desc1:'毕业于中山大学',
      desc2:'获选2020福布斯中国30岁以下青年精英',
      desc3:'5 年私募基金投资经验',
      desc4:'香港身份全案专家',
      desc5:'香港TTPS计划人才',
    },
    team2:{
      name:'Ray 潘晨先生',
      position:'总主理人',
      desc1:'厦门大学毕业',
      desc2:'10年香港移民公司经验',
      desc3:'5千+香港身份全案规划',
      desc4:'国际教育工作者协会会员',
      desc5:'国际教育计划认证导师',
      desc6:'海外升学指导协会成员',
      desc7:'剑桥大学官方认可升学顾问',
      desc8:'KITO留学服务中心全球项目总监',
    },
    team3:{
      name:'Carson 黎燕君女士',
      position:'项目经理/主理人',
      desc1:'10年大型移民公司高管经验',
      desc2:'1500+香港、美国、土耳其身份全案规划',
      desc3:'精通大国护照、技术移民、签证项目规划',
      desc4:'桃原国际核心主理人',
      desc5:'KITO移民服务中心项目总监',
    },
  },

  // 联系我们
  contactUs:{
    title: '联系我们',
    baseInfo:{
      label1:'客服热线',
      label2:'商务合作',
      label3:'招聘相关',
      label4:'办公地点',
      desc4:'深圳宝安卓越宝中时代广场A座',
      desc4_4:'',
      label5:'营业执照',
      desc5:'查看图片',
      label6:'公众号',
      desc6:'桃原国际',
    },
  },

  // 零散的
  others:{
    chineseLang:'中文',
  }
}
