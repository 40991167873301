<template>
  <div class="product-slide-one">
    <div class="top-view">
      <p class="title">
        <span>{{ $t('productServices.card1.text1') }}</span>
        <span class="stand">{{ $t('productServices.card1.text2') }}</span>
        <span>{{ $t('productServices.card1.text3') }}</span>
       </p>
      <p class="desc">{{$t('productServices.card1.desc')}}</p>
      <img class="project-img" loading="lazy" v-if="locale == 'zh'" :src="`${$COS}/project_yc.png`" alt="">
      <img class="project-img" loading="lazy" v-if="locale == 'en'" :src="`${$COS}/en_project_yc.png`" alt="">
    </div>
    <div class="flow-view">
      <div class="flow-text">{{$t('productServices.tips')}}</div>
      <img class="progress-img" loading="lazy" :src="`${$COS}/img_progress.png`">
      <div class="progress-view">
        <div class="flow-item" v-for="item in productProgress1" :key="item">
          <p :class="`item-title ${locale}-item-title`">{{item.label}}</p>
          <p class="item-desc">{{item.tip}}</p>
          <div class="item-list">
            <p v-for="i in item.progressList" :key="i">{{ i }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
const { locale } = useI18n();
import { productProgress1 }  from '@/utils/enumeration'


</script>


<style scoped lang="scss">
@import './product.scss';

</style>
