<template>
  <div class="slide-four-page">
    <!-- <div class="left-menu"></div> -->
    <div class="content-view-bg">
      <div class="content-view">
        <div class="title">{{$t("successCase.pageTitle")}}</div>
        <div class="swiper-content-view">
          <swiper
            :slidesPerView="2"
            :loop="true"
            :autoplay="{
              delay: 500,
              disableOnInteraction: false,
            }"
            :speed="5000"
            :space-between="30"
            @swiper="initSwiper"
            :modules="modules"
            :centeredSlides="true"
            >
            <swiper-slide class="swiperSlide" v-for="item in caseImgList" :key="item.name">
              <!-- <caseView :option="item" /> -->
              <caseImageView :option="item" @showImg="showCaseImg" />
            </swiper-slide>
          </swiper>
        </div>
        <div class="evaluate-view">
          <p class="evaluate-text">{{$t('userReviews.title')}}</p>
          <div>
            <div class="top">
              <div class="user-view">
                <img class="head-img" :src="reviewInfo.headSrc" alt="">
                <p class="name">{{reviewInfo.name}}</p>
                <p class="desc">｜ {{reviewInfo.position}}</p>
              </div>
              <div class="change-btn" @click="nextReview">
                <img class="change-img" :src="`${$COS}/icon_change.png`" alt="">
                {{$t('userReviews.changeText')}}
              </div>
            </div>
            <div class="text-view">
              {{reviewInfo.remark}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="left-mask-view">
      <img class="left-mask-img" :src="`${$COS}/bg_mask2.png`" >
    </div>
    <div class="right-mask-view">
      <img class="right-mask-img" :src="`${$COS}/bg_mask2.png`" >
    </div>
    <div class="left-view-bg">
      <div class="left-view">
        <div class="tip">
          <p class="border"></p>
          <p class="title">{{$t("successCase.title")}}</p>
        </div>
        <img class="menu-img" :src="`${$COS}/menu_blue.png`">
      </div>
    </div>
    <div class="mask-view-bg" v-if="selectSrc">
      <div class="mask-view">
        <img :src="selectSrc" class="mask-img" >
        <img :src="`${$COS}/img_close.png`" @click="handleClose" class="close-img" />
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import caseImageView from './case/caseImageView'
import { Autoplay  } from 'swiper/modules';

export default {
  components: {
    Swiper,
    SwiperSlide,
    caseImageView
  },
  props: {
    activeIndex: {
      type: Number
    }
  },
  computed: {
    reviewInfo () {
      return this.reviews[this.reviewsIndex]
    },
    caseList() {
      return [
        {
          title: this.$t('successCase.case1.title'),
          name: this.$t('successCase.case1.name'),
          cycle: this.$t('successCase.case1.cycle'),
          grade: this.$t('successCase.case1.grade'),
          desc: this.$t('successCase.case1.desc'),
        },
        {
          title: this.$t('successCase.case2.title'),
          name: this.$t('successCase.case2.name'),
          cycle: this.$t('successCase.case2.cycle'),
          grade: this.$t('successCase.case2.grade'),
          desc: this.$t('successCase.case2.desc'),
        },
        {
          title: this.$t('successCase.case3.title'),
          name: this.$t('successCase.case3.name'),
          cycle: this.$t('successCase.case3.cycle'),
          grade: this.$t('successCase.case3.grade'),
          desc: this.$t('successCase.case3.desc'),
        },
        {
          title: this.$t('successCase.case4.title'),
          name: this.$t('successCase.case4.name'),
          cycle: this.$t('successCase.case4.cycle'),
          grade: this.$t('successCase.case4.grade'),
          desc: this.$t('successCase.case4.desc'),
        }
      ]
    },
    caseImgList () {
      return [
      {
          type: this.$t('successCase.case1.type'),
          imagesList: [
            `${this.$COSM}/yc_1@2x.png`,
            `${this.$COSM}/yc_2@2x.png`,
            `${this.$COSM}/yc_3@2x.png`
          ]
        },
        {
          type: this.$t('successCase.case2.type'),
          imagesList: [
            `${this.$COSM}/gc_1@2x.png`,
            `${this.$COSM}/gc_2@2x.png`,
            `${this.$COSM}/gc_3@2x.png`
          ]
        },
        {
          type: this.$t('successCase.case3.type'),
          imagesList: [
            `${this.$COSM}/jx_1@2x.png`,
            `${this.$COSM}/jx_2@2x.png`,
            `${this.$COSM}/jx_3@2x.png`
          ]
        },
        {
          type: this.$t('successCase.case4.type'),
          imagesList: [
            `${this.$COSM}/zc_1@2x.png`,
            `${this.$COSM}/zc_2@2x.png`,
            `${this.$COSM}/zc_3@2x.png`
          ]
        },
        {
          type: this.$t('successCase.case5.type'),
          imagesList: [
            `${this.$COSM}/qt_1@2x.png`,
            `${this.$COSM}/qt_2@2x.png`,
            `${this.$COSM}/qt_3@2x.png`
          ]
        },
      ]
    },
    reviews () {
      return [
        {
          name: this.$t('userReviews.review1.name'),
          headSrc: `${this.$COS}/avatar_1.png`,
          position: this.$t('userReviews.review1.position'),
          remark: this.$t('userReviews.review1.remark')
        },
        {
          name: this.$t('userReviews.review2.name'),
          headSrc: `${this.$COS}/avatar_3.png`,
          position: this.$t('userReviews.review2.position'),
          remark: this.$t('userReviews.review2.remark')
        },
        {
          name: this.$t('userReviews.review3.name'),
          headSrc: `${this.$COS}/avatar_4.png`,
          position: this.$t('userReviews.review3.position'),
          remark: this.$t('userReviews.review3.remark')
        },
        {
          name: this.$t('userReviews.review4.name'),
          headSrc: `${this.$COS}/avatar_2.png`,
          position: this.$t('userReviews.review4.position'),
          remark: this.$t('userReviews.review4.remark')
        },
        {
          name: this.$t('userReviews.review5.name'),
          headSrc: `${this.$COS}/avatar_1.png`,
          position: this.$t('userReviews.review5.position'),
          remark: this.$t('userReviews.review5.remark')
        },
        {
          name: this.$t('userReviews.review6.name'),
          headSrc: `${this.$COS}/avatar_3.png`,
          position: this.$t('userReviews.review6.position'),
          remark: this.$t('userReviews.review6.remark')
        },
        {
          name: this.$t('userReviews.review7.name'),
          headSrc: `${this.$COS}/avatar_4.png`,
          position: this.$t('userReviews.review7.position'),
          remark: this.$t('userReviews.review7.remark')
        },
        {
          name: this.$t('userReviews.review8.name'),
          headSrc: `${this.$COS}/avatar_1.png`,
          position: this.$t('userReviews.review8.position'),
          remark: this.$t('userReviews.review8.remark')
        }
      ]
    }
  },
  data () {
    return {
      modules: [Autoplay],
      reviewsIndex: 0,
      swiper: null,
      selectSrc: null,
      index: 0
    }
  },
  methods: {
    handleClose () {
      this.selectSrc = null
    },
    initSwiper (sw) {
      this.swiper = sw
    },
    showCaseImg (src) {
      this.selectSrc = src
    },
    nextReview () {
      this.index ++
      if (this.index > 7) {
        this.index = 0
        this.reviewsIndex = this.index
        return
      }
      this.reviewsIndex = this.index
    }
  },
  watch: {
    activeIndex(newVal) {
      if (newVal == 3) {
        if (this.swiper) {
          // 判断是否处于 自动播放
          console.log('this.swiper.autoplay.running', this.swiper.autoplay.running)
          if (!this.swiper.autoplay.running) {
            this.swiper.autoplay.start()
          }
        }
        this.nextReview()
      }
    }
  }

}
</script>

<style scoped lang="scss">

.slide-four-page{
  width: 100%;
  height: 100%;
  position: relative;
  background: #E1EEFF;
  .content-view-bg{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    .content-view{
      flex:1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .title{
        font-size: 32px;
        color: #2E3A6B;
        line-height: 40px;
        font-weight: bolder;
      }
      .swiper-content-view{
        margin-top: 60px;
        .swiper {
          max-width: 1450px;
          min-height: 260px;
        }
        .swiper-slide {
          display: block;
          height: 100%;
          object-fit: cover;
        }
      }
      .evaluate-view{
        width: 700px;
        margin-top: 60px;
        .evaluate-text{
          position: relative;
          font-size: 24px;
          color: #2E3A6B;
          line-height: 40px;
          font-weight: bolder;
          display: inline-block;
          z-index: 10;
          &::before{
            z-index: -1;
            position: absolute;
            content: '';
            bottom: 0;
            right: -20px;
            width: 120px;
            height: 20px;
            opacity: 0.6;
            background-image: linear-gradient(90deg, #77aaef00 0%, #689FEA 100%);
            border-radius: 10px;
          }
        }
        .top{
          margin-top: 60px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .user-view{
            display: flex;
            align-items: center;
            .head-img{
              height: 28px;
              width: 28px;
            }
            .name{
              font-size: 14px;
              color: #2E3A6B;
              line-height: 40px;
              font-weight: bolder;
              margin: 0 12px;
            }
            .desc{
              font-size: 12px;
              color: #2E3A6B;
              line-height: 20px;
            }
          }
          .change-btn{
            display: flex;
            align-items: center;
            font-size: 12px;
            color: #2E3A6B;
            cursor: pointer;
            .change-img {
              height: 18px;
              display: block;
              margin-right: 5px;
            }
          }
        }
        .text-view{
          margin-top: 12px;
          font-size: 12px;
          color: #FFFFFF;
          text-align: justify;
          line-height: 20px;
          padding: 30px 40px;
          background: url('https://qtiao-web-1258997378.cos.ap-guangzhou.myqcloud.com/taoyuanintl/pc/img_comment.png');
          background-size: 100% 100%;
        }
      }
    }
  }
  .left-mask-view{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 420px;
    z-index: 2;
    .left-mask-img{
      height: 100%;
      width: 100%;
    }
  }
  .right-mask-view{
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    z-index: 2;
    width: 420px;
    .right-mask-img{
      height: 100%;
      width: 100%;
      transform: rotate(180deg);
    }
  }
  .left-view-bg{
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 10;
    transform: translate(0, -50%);
    .left-view{
      position: relative;
      .tip{
        position: absolute;
        left: 95px;
        top: 260px;
        font-size: 20px;
        color: #2E3A6B;
        line-height: 40px;
        height: 40px;
        font-weight: bolder;
        display: flex;
        align-items: center;
        white-space: nowrap;
        .border{
          width: 12px;
          height: 12px;
          background-color: #2E3A6B;
          border-radius: 50%;
          margin-right: 20px;
        }
        .title{
          text-align: left;
          width: 120px;
          height: 40px;
          white-space: break-spaces;
        }
      }
      .menu-img{
        height: 500px;
        display: block;
      }
    }
  }

  .mask-view-bg{
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99;
    display: flex;
    &::before{
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
      background: url('https://qtiao-web-1258997378.cos.ap-guangzhou.myqcloud.com/taoyuanintl/pc/bg_mask.png') no-repeat;
      // transform: rotate(180deg);
    }
    .mask-view{
      position: relative;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .mask-img{
        margin-top: 90px;
        width: 420px;
        height: 540px;
      }
      .close-img{
        margin-top: 50px;
        width: 40px;
        height: 40px;
        display: block;
        cursor: pointer;
      }
    }
  }
}
</style>
