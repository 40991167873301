
// 定义英文语言包对象
export default {
  title:'Taoyuan International',
  video:{
    title: 'Home',
    desc1: 'Taoyuan International Management Consulting Co., Ltd. is a subsidiary company of Kito International.',
    desc1_1: 'With the concept of focusing on Hong Kong, leading talents to go global, servicing online, connecting the future， ',
    desc2: 'we aim to provide individual users and partnership channels with the most professional one-stop comprehensive ',
    desc2_2: 'services for Hong Kong identity. Including the Quality Migrant Admission Scheme, Top Talent Pass Scheme, ',
    desc3: 'Hong Kong Study Program, and Admission Scheme for Mainland Talents and Professionals (ASMTP).',
    desc4: 'Scroll down to learn more',
  },
  companyAbout: {
    title: 'Company Profile',
    desc: `Taoyuan International has operated branches in Shenzhen, Hong Kong, Xiamen, and Singapore, with a team of over 70 professional staff. TThe core members all come from leading enterprises in international immigration and overseas education, with over 50% possessing Hong Kong identities, and over 80% holding master's degrees or having overseas study experiences.`,
  },
  // 产品服务
  productServices:{
    title: 'Product Services',
    moreText: 'More Schemes',
    tips: 'Application Process',
    card1:{
      text1:'',
      text2:'Quality Migrant',
      text3:' Admission Scheme',

      desc:'One person applies, and the entire family can obtain Hong Kong residency.',

      tool1:'Open to applicants worldwide',
      tool2:'Benefits for the whole family',
      tool3:'Golden gateway',
      tool4:'Short processing period',
      tool5:'Excellent social welfare',
      tool6:'High-quality education',
      tool7:'Offering more visa-free countries',
      tool8:'Efficient and fast processing',

      progress1Label:'Signing of Service Agreement ',
      progress1Tip:'(1 day)',
      progress1Desc1:'Signing of agreement for Hong Kong identity application; ',
      progress1Desc2: 'Payment of service fees.',

      progress2Label:'Preparation and submission of documents ',
      progress2Tip:'(approximately 1-2 months)',
      progress2Desc1:'Completion of personal',
      progress2Desc2:'information and program',
      progress2Desc3:'positioning.',

      progress3Label:'Waiting for approval',
      progress3Tip:'(approximately 6 months)',
      progress3Desc1:'Issuance of exclusive file number',
      progress3Desc2:'Awaiting screening results from Hong Kong Immigration Department.',

      progress4Label:'Approval and issuance of visa',
      progress4Tip:'After approval',
      progress4Desc1:'Obtaining Hong Kong visa;',
      progress4Desc2:'And official approval letter.',

      progress5Label:'Processing of Hong Kong identity card',
      progress5Tip:'(approximately 1 month)',
      progress5Desc1:'Processing of D visa for stay',
      progress5Desc2:'Entry into Hong Kong to activate the visa',
      progress5Desc3:'Processing of Hong Kong identity card.',

      progress6Label:'Visa Renewal',
      progress6Tip:'(preparation starts half a year in advance, approximately 1 month for each renewal)',
      progress6Desc1:'Renewal in the form of 3+3+2',
      progress6Desc2:'One month before the visa expiration',
      progress6Desc3:'Submission of visa renewal application',

      progress7Label:'Permanent residency',
      progress7Tip:'(approximately 1-2 months)',
      progress7Desc1:'Prepare permanent residency documents for submission to immigration authorities',
      progress7Desc2:'Process the permanent residency identity card after approval; And Hong Kong passport.',
    },
    card2:{
      text1:'Top Talent ',
      text2:'Pass Scheme',

      desc:'2-year visa with fast approval speed',

      tool1:'Category A Applicants',
      tool2:'Annual income of 2.5 million Hong Kong dollars in the past year.',

      tool3:'Category B Applicants',
      tool4:`Bachelor's degree from a top 100 global university, with three years of work experience in the past five years.`,

      tool5:'Category C Applicants',
      tool6:`Bachelor's degree from a top 100 global university, with less than 3 years of work experience.`,
      tool7:'(Annual quota of 10,000)',

      progress1Label:'Signing of Service Agreement',
      progress1Tip:'(1 day)',

      progress2Label:'Preparation and Submission of Documents',
      progress2Tip:'(Approximately 1 week)',

      progress3Label:'Waiting for Approval',
      progress3Tip:'(A pproximately 4-6 weeks)',

      progress4Label:'Approval and Visa Issuance',
      progress4Tip:'After Approval',

      progress5Label:'Applying for Hong Kong Identity Card',
      progress5Tip:'(approximately 1 month)',

      progress6Label:'Visa Renewal',
      progress6Tip:'Visa Renewal for 2 times \n(Prepare 1 year in advance, each renewal takes approximately 1 month)',

      progress7Label:'Permanent Residency',
      progress7Tip:'(approximately 1-2months)',
    },
    card3:{
      text1:'Hong Kong',
      text2:' Study Program',
      text3:'',

      desc:'Tailored education and study plan, with continuous follow-up on application progress',

      tool1Title:'Personalized customization',
      tool1Desc1:'"Global perspective, tailored to your needs"',
      tool1Desc2:'Designing personalized programs for clients.',

      tool2Title:'Top-notch consultants',
      tool2Desc:'A team of professionals with extensive experience in top school applications and studies.',

      tool3Title:'High-quality resources',
      tool3Desc1:'Collaboration with top-tier institutions;',
      tool3Desc2:'Connect with 50+ specialized channels and suppliers.',

      tool4Title:'End-to-end service',
      tool4Desc1:'Comprehensive study abroad services;',
      tool4Desc2:'Including school selection, application, visa, and renewal preparations.',

      progress1Label:'Needs Analysis',
      progress1Desc:'Professional consultants engage in in-depth communication with clients to understand their background, needs, and goals, assisting them in determining the optimal educational and training programs.',

      progress2Label:'School Selection and Application',
      progress2Desc:`Tutors recommend the most suitable schools based on the client's needs and background, and assist them in preparing application materials and submitting the application.`,

      progress3Label:'Writing Application Documents',
      progress3Desc:'Our professional team of writers will create high-quality personal statements, recommendation letters, resumes, and other application documents for our clients to enhance their chances of admission.',

      progress4Label:'Interview Coaching',
      progress4Desc:'Our professional mentors will provide clients with interview coaching, including mock interviews and targeted question preparation, to help them overcome interview nerves and improve their performance.',

      progress5Label:'Follow-up Services',
      progress5Desc:'We will assist clients in visa processing and travel arrangements, ensuring comprehensive support and assistance for their study abroad experience.',
    },
    card4:{
      text1:'EB1A and NIW',
      text2:' in the United States',

      desc:'EB1A takes 3-4 years and NIW takes 5-6 years, providing a direct path to US Green Card',

      tool1:'Employment-Based Immigration:  \nFirst Preference EB-1 (Extraordinary Ability)',
      tool2:'Individuals who have extraordinary ability in the sciences, arts, education, business, or athletics which has been demonstrated by sustained national or international acclaim, whose achievements have been recognized in their respective domains, positioning them as top figures in their fields. Applicants are required to meet at least 3 out of 10 criteria, which include awards, associations membership, interviews, judgeships, originality, papers, exhibitions, leading roles, high salary, and business achievements.',
      tool3: 'Employment-Based Immigration: \nSecond Preference EB-2 (NIW)',
      tool4:'Applicants must have an Advanced Degree or Exceptional Ability, and meet the following requirements:',
      tool5:'1. The proposed endeavor has both substantial merit and national importance.',
      tool6:'2.You are well positioned to advance the proposed endeavor.',
      tool7:'3. On balance, it would be beneficial to the United States to waive the requirements of a job offer, and thus the labor certification.',

      progress1Label:'Signing of processing agreement',
      progress1Tip:'(1 day)',

      progress2Label:'Preparation and submission of documents',
      progress2Tip:'(approximately 4-6 months)',

      progress3Label:'Case processing',
      progress3Tip:'(approximately 1-1.5 years)',

      progress4Label:'Waiting for priority date',
      progress4Tip:'Dynamic changes in priority date',

      progress5Label:'NVC (National Visa Center) processing ',
      progress5Tip:'(approximately 1-12 months)',

      progress6Label:'Medical examination and interview',
      progress6Tip:'(approximately 1 month)',

      progress7Label:'Entry into US and collection of the green card ',
      progress7Tip:'(approximately 1-3 months)',
    },
    card5:{

      text1:'Admission Scheme for Mainland ',
      text2:'Entrepreneurs',
      text3:'',

      desc:'A special form of the Admission Scheme for Mainland Talents and Professionals (ASMTP).',

      progress1Label:'Needs Analysis',
      progress1Tip:`Gain an in-depth understanding of the client's background, education, work experience, and other relevant factors to accurately address their needs and goals.`,

      progress2Label:'Tailored Solutions',
      progress2Tip:`Based on the client's needs and goals, and with the principle of successful approval in mind, we will develop a highly flexible and executable personalized admission scheme tailored to the client.`,

      progress3Label:'Execution',
      progress3Tip:'Execute the Plan: Establish a Hong Kong company, hire local employees, handle MPF, provide banking services, offer company secretary services.',

      progress4Label:'Application',
      progress4Tip:'Our writing experts will create high-quality personal statements, recommendation letters, and other application documents for clients to enhance their approval chances.',

      progress5Label:'Follow-up Service',
      progress5Tip:'With extensive experience in managing client cases, we employ targeted and strategic approaches for submitting supplementary documents, offering precise responses to official queries, and ensuring a smooth approval process.'

    },
    card6:{

      text1:'Admission Scheme for Mainland ',
      text2:'Nurses',
      text3:'',

      desc:'Mainland nurses apply for Hong Kong identity and employment through the ASMTP.',

      progress1Label:'Resume Submission',
      progress1Tip:'Prepare the resume and submit it to targeted employers.',

      progress2Label:'Initial Interview',
      progress2Tip:'Online initial interview with recruitment agency.',

      progress3Label:'Final Interview (In-person)',
      progress3Tip:'In-person final interview with the Hong Kong employer.',

      progress4Label:'Accept the Offer',
      progress4Tip:'Accept the offer and sign the contract.',

      progress5Label:'Visa Application',
      progress5Tip:'Submit the application for ASMTP to obtain your Hong Kong Visa.',

      progress6Label:'Employment ',
      progress6Tip:'Relocate to Hong Kong for employment and successfully join the company.',

      progress7Label:'Career Advancement',
      progress7Tip:'Complete necessary training and acquire certificates, and become a Hong Kong registered nurse. '

    },
    card7:{
      text1:'Hong Kong ',
      text2:'Extension of Stay',

      desc:'Submit an application for extension to the Hong Kong Immigration Department before the expiry of the current visa.',

      progress1Label:'Needs Analysis',
      progress1Tip:'Professional consultants engage in in-depth communication with clients to understand their background, needs, and goals, helping them determine the best visa renewal plan.',

      progress2Label:'Renewal Strategy',
      progress2Tip:'Customize a detailed residency plan and work schedule based on renewal requirements and the client’s specific circumstances.',

      progress3Label:'Process Management',
      progress3Tip:'Coordinate with local resources in Hong Kong to provide assistance with living and employment, and follow up on the implementation of the plan.',

      progress4Label:'Application Preparation',
      progress4Tip:'Collect and organize the required renewal documents, including travel records to Hong Kong and employment certification, and draft the necessary application statements.',

      progress5Label:'Visa Renewal Approved',
      progress5Tip:'Assist clients in submitting renewal applications, monitor the application status, and employ targeted, strategic document supplementation to ensure a timely renewal process.',

    },
  },
  // 成功案例
  successCase:{
    title: 'Success Case',
    pageTitle: 'Customer Approval Case',
    case1:{
      title:'Approval Case of Quality Migrant Admission Scheme',
      type: 'QMAS',
      name:`Applicant: Ms. S ｜ Age: 35 ｜ Bachelor's degree`,
      cycle:'Approval Period: 9 months',
      grade:'Score: 160 points',
      desc:`Ms. S graduated from one of the top 50 universities worldwide according to the QS rankings and had a background of studying abroad. After graduation, she worked for a well-known import-export company for 13 years, specializing in international trade. She steadily rose through the ranks and became a sales director. \nThrough effective communication with Ms. S, we emphasized her international background, the advantages of working for a prestigious company, and her extensive qualifications in the document preparation process. We also tailored her profile to highlight the unique characteristics and development needs of Hong Kong's trade industry, creating an appealing image for her. Ultimately, our efforts successfully assisted Ms. S in obtaining approval in 2023.`,
    },
    case2:{
      title:'Approval Case of Quality Migrant Admission Scheme',
      type: 'TTPS',
      name:`Applicant: Mr. Z ｜ Age: 31 ｜ Master's degree`,
      cycle:'Approval Period: 4 months',
      grade:'Score: 145 points',
      desc:`Mr. Z holds a bachelor's degree in Human Resource Management from a non-elite domestic university and a master's degree from a globally recognized top 50 university according to QS rankings. He has previously worked for several well-known internet companies and has experience in supply chain management and information technology platform construction. \nThrough detailed communication and analysis, we highlighted Mr. Z's advantages of working for prestigious companies, graduating from renowned universities, and having an international background. We particularly emphasized his strong capabilities in supply chain management, which aligns with the current development needs in Hong Kong. As a result, we successfully assisted Mr. Z in obtaining approval in 2023.`,
    },
    case3:{
      title:'Approval Case of Top Talent Pass Scheme Category A',
      type: 'IANG',
      name:'Applicant: Mr. P ｜ Age: 40 ',
      cycle:'Approval Period: 2 months',
      grade: '',
      desc:`Mr. P, currently the CEO of a data technology company, is a shareholder receiving company dividends. From September 2022 to September 2023, he earned nearly 5 million RMB and paid approximately 1.6 million RMB in taxes. Our team assisted Mr. P in organizing comprehensive supporting documents,  including employment proof (employment contract, employment certificate, social security records), income and tax proof (income details, tax records, income tax summary details), and other relevant materials. With the thorough preparation of these documents, we successfully helped Mr. P obtain approval at the end of October 2023.`,
    },
    case4:{
      title:'Approval Case of Hong Kong Study Program',
      type: 'ASMTP',
      name:'Applicant: Mr. C ｜ Age: 26',
      cycle:'Approval Period: 1 month',
      grade: '',
      desc:`Mr. C graduated with a bachelor's degree in management from a non-elite university in mainland China. He has some experience in financial investment and currently takes over the family business. He is single and unmarried. Prior to contacting us, Mr. C had previously failed in his Talent Immigration Program. After thorough communication, he ultimately chose the Bachelor of Business Administration (Entrepreneurship Management) program at the Hong Kong Chu Hai College as his desired direction of study. \nWe tailored our approach to align with the college's admission requirements and emphasized Mr. C's mature business management experience and achievements in the family business. Additionally, we provided customized interview coaching services. As a result, we successfully assisted Mr. C in obtaining approval in November 2023.`,
    },
    case5:{
      title:'Approval Case of Hong Kong Study Program',
      type: 'Others',
      name:'Applicant: Mr. C ｜ Age: 26',
      cycle:'Approval Period: 1 month',
      grade: '',
      desc:`Mr. C graduated with a bachelor's degree in management from a non-elite university in mainland China. He has some experience in financial investment and currently takes over the family business. He is single and unmarried. Prior to contacting us, Mr. C had previously failed in his Talent Immigration Program. After thorough communication, he ultimately chose the Bachelor of Business Administration (Entrepreneurship Management) program at the Hong Kong Chu Hai College as his desired direction of study. \nWe tailored our approach to align with the college's admission requirements and emphasized Mr. C's mature business management experience and achievements in the family business. Additionally, we provided customized interview coaching services. As a result, we successfully assisted Mr. C in obtaining approval in November 2023.`,
    }
  },
  // 用户好评
  userReviews:{
    title: 'Customer Testimonial',
    changeText: 'Next',
    review1:{
      name:'Mr. Chen',
      position:'Software Company Senior Engineer',
      remark:'Thank you, Teacher Pan, for your hard work. I applaud your dedication and the exceptional quality of the proposal. I am truly grateful. The whole document stands out prominently and shines brightly.'
    },
    review2:{
      name:'Ms. Kang',
      position:'Regional Manager of a Large Supermarket',
      remark:`Thanks Teacher Pan! Your writing is truly amazing! It is much more professional than what we could have done ourselves. I think it's excellent! It has even inspired me in my own life.`
    },
    review3:{
      name:'Ms. Li',
      position:`Master's Degree Returnee, Large Internet Company HR`,
      remark:`I just finished reading it, wow, it's really amazing! I don't think there's anything that needs to be changed, your talent is truly outstanding. I feel so hopeful. I'm going to start preparing my English self-introduction later.`
    },
    review4:{
      name:'Mr. Wang',
      position:'Architectural Design Engineer',
      remark:`Great effort! Still producing content on the weekend,  thanks for your dedication. I just finished reviewing the proposal and overall, the structure is clear and the language is fluent. I don't have any further changes to suggest at this time.`
    },
    review5:{
      name:'Mr. Luo',
      position:' Large Insurance Conglomerate Managerial Staff ',
      remark:`Teacher Pan, you are so efficient! Your logic is clear, and your thinking is meticulous. I chose to focus on the finance aspect of my work at Ping An Bank, so I didn't write much about the marketing aspect. Could you please help me further optimize it based on this?`
    },
    review6:{
      name:'Ms. Wang',
      position:'Logistics Company Supervisor',
      remark:'"Reading your articles makes me blush a little. hhhh” With the support of our written documents, regardless of the final outcome, I get a lot of confidence. I will prepare diligently.'
    },
    review7:{
      name:'Miss Ren',
      position:'a senior professional in the medical industry',
      remark:`You've conducted such detailed research on our company's product line. Many of our own employees may not even be able to accurately assess the differences between us and the general anti-aging industry. Your team is truly professional!`
    },
    review8:{
      name:'Mr. Li',
      position:'a game producer in a major game studio',
      remark:`I've finished reading it, and your team is truly professional. You've analyzed the domestic gaming market and the international market for overseas gaming so thoroughly. It feels like Teacher Xiao Pan has experience in market research work, doesn't he?`
    },
  },
  // 管理团队
  manageTeam:{
    title: 'Management Team',
    team1:{
      name:'Mr. Hyan 杨国江',
      position:'Founder',
      desc1:'- 5 years of executive experience in a Hong Kong Financial Group;',
      desc2:'- 5 years of executive experience in an immigration company;',
      desc3:'- Technology development advisor at the Hong Kong Monetary Authority;',
      desc4:'- Overseas investment advisor for multiple listed companies;',
      desc5:'- Director and partner at Kito International;',
      desc6:'- Founder of Guanghe Wealth Family Office.'
    },
    team4:{
      name:'Mr. Marc 刘宇斌',
      position:'Partner',
      desc1:'- Graduate of Sun Yat-sen University;',
      desc2:'- Forbes China 30 Under 30 in 2020;',
      desc3:'- 5 years of private equity investment experience;',
      desc4:'- Expert in comprehensive Hong Kong immigration services;',
      desc5:'- Talent under Hong Kong’s TTPS',
    },
    team2:{
      name:'Mr. Ray 潘 晨',
      position:'Chief Principal',
      desc1:'- Graduate of Xiamen University;',
      desc2:'- 10 years of experience in a Hong Kong immigration company;',
      desc3:'- Over 5,000 cases of comprehensive Hong Kong identity planning;',
      desc4:'- Member of the International Education Workers Association;',
      desc5:'- Certified tutor of International Education Programs;',
      desc6:'- Member of Overseas Association for College Admission Counseling;',
      desc7:'- Officially recognized educational consultant by the University of Cambridge',
      desc8:'- Global Program Director of Kito International Overseas Study Service Center.',
    },
    team3:{
      name:'Ms. Carson 黎燕君',
      position:'Project Manager/Principal',
      desc1:'- 10 years of executive experience in a large immigration company;',
      desc2:'- 1500+ comprehensive planning for Hong Kong, US, and Turkish identities',
      desc3:'- Proficient in planning for major country passports, skilled immigration, and visa projects;',
      desc4:'- Core Principal of Taoyuan International;',
      desc5:'- Project Director of Kito International Immigration Service Center.',
    },
  },

  // 联系我们
  contactUs:{
    title: 'Contact',
    baseInfo:{
      label1:'Customer Service Hotline',
      label2:'Cooperation Contact',
      label3:'Recruitment Related',
      label4:'Office Location',
      desc4:`Block A, Zhuoyue Baozhong Times Square, Bao'an District, Shenzhen`,
      desc4_4:'深圳宝安卓越宝中时代广场A座',
      label5:'Business License',
      desc5:'Click to view',
      label6:'Wechat Official Account',
      desc6:'桃原国际',
    },
  },

  // 零散的
  others:{
    chineseLang:'中文',
  }
}
