<template>
  <div class="case-view">
    <div class="type-view">{{option.type}}</div>
    <div class="images-view">
      <img v-for="(item,index) in option.imagesList" @click="handleImg(item)" :key="index" :src="item"  alt="" >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    option:{
      default: () => ({})
    }
  },
  methods: {
    handleImg (src) {
      this.$emit('showImg', src)
    }
  }
}

</script>

<style scoped lang="scss">
.case-view{
  padding: 50px 80px 30px;
  background-color: #FFF;
  text-align: left;
  border-radius: 16px;
  position: relative;
  .type-view{
    position: absolute;
    top:0;
    left: 0;
    line-height: 40px;
    font-size: 14px;
    color: #FFFFFF;
    font-weight: bold;
    padding: 0 20px;
    background: #649DEC;
    border-radius: 16px 0 16px 0;
  }
  .images-view{
    display: flex;
    justify-content: space-between;
    >img{
      width: 140px;
      height: 180px;
      display: block;
      cursor: pointer;
    }
  }
}
</style>

