<template>
  <div class="top-view">
    <template  v-if="slideIndex">
      <img class="logo-img" loading="lazy" :src="`${$COS}/img_logo_white.png`" v-if="props.slideIndex == 1">
      <img class="logo-img" loading="lazy" :src="`${$COS}/img_logo.png`" v-else>
      <div :class="`language-text ${props.slideIndex == 1 ? 'white-text':''}`">
        <span @click="changeLang('zh')">{{$t('others.chineseLang')}}</span>
        <span @click="changeLang('en')">｜ EN</span>
      </div>
    </template>
    <template v-else>
      <div class="language-text white-text home-lang">
        <span @click="changeLang('zh')">{{$t('others.chineseLang')}}</span>
        <span @click="changeLang('en')">｜ EN</span>
      </div>
    </template>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
const { locale, t } = useI18n();
import {defineProps} from 'vue'
import {useRouter}  from 'vue-router'

const router = useRouter()

const props = defineProps({
  slideIndex:{
    type: Number,
    default: 0
  }
})


const changeLang = (lang)=>{
  locale.value = lang;
  document.title = t('title')
  router.replace(`/${lang}`)
}
</script>

<style scoped lang="scss">
.top-view{
  z-index: 9;
  position: absolute;
  top: 0;
  left: 0;
  height: 120px;
  display: flex;
  align-items: center;
  .logo-img{
    height: 120px;
  }
  .language-text{
    cursor: pointer;
    font-size: 14px;
    color: #5A68A1;
    font-weight: bolder;
  }
  .white-text{
    color: #FFF;
  }
  .home-lang{
    padding-left: 95px;
  }
}
</style>
