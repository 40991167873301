<template>
  <div class="slide-five-page">
    <div class="left-view-bg">
      <div class="left-view">
        <div class="tip">
          <p class="border"></p>
          <p class="title">{{$t("manageTeam.title")}}</p>
        </div>
        <img class="menu-img" :src="`${$COS}/menu_blue.png`">
      </div>
    </div>
    <div class="mask-view">
      <div class="content-view">
        <div class="left-items">
          <div class="item" v-for="(item,index) in manageTeams" :key="item.name">
            <p :class="`name-view ${activeIndex == index ? 'name-view-active':''}`" @click="activeIndex = index">{{item.name}}</p>
            <p class="desc" v-if="index == activeIndex">{{item.desc}}</p>
            <div :class="`tips ${locale}-tips`" v-if="index == activeIndex">
              <p v-for="i in item.tips" :key="i">{{i}}</p>
            </div>
          </div>
        </div>
        <div class="right-img">
          <img class="photo-img" :src="manageTeams[activeIndex][`imgSrc_${locale}`]" alt="">
          <img @click="handleNext" :src="`${$COS}/btn_next.png`" class="next-img">
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from 'vue'
import { manageTeams }  from '@/utils/enumeration'
import { useI18n } from "vue-i18n";
const { locale } = useI18n();


const activeIndex = ref(0)

const handleNext = () => {
  const total = manageTeams.value.length
  if (activeIndex.value == total - 1) {
    activeIndex.value = 0
    return
  }
  activeIndex.value ++
}


</script>


<style scoped lang="scss">
.slide-five-page{
  width: 100%;
  height: 100%;
  position: relative;
  background-image: linear-gradient(360deg, #FFFFFF 47%, #E1EEFF 100%);
  display: flex;
  .left-view-bg{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    .left-view{
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .tip{
        position: absolute;
        left: 95px;
        bottom: 135px;
        font-size: 20px;
        color: #2E3A6B;
        line-height: 40px;
        height: 40px;
        font-weight: bolder;
        display: flex;
        align-items: center;
        white-space: nowrap;
        .border{
          width: 12px;
          height: 12px;
          background-color: #2E3A6B;
          border-radius: 50%;
          margin-right: 20px;
        }
        .title{
          text-align: left;
          height: 40px;
          white-space: break-spaces;
          width: 120px;
        }
      }
      .menu-img{
        height: 500px;
        display: block;
      }
    }
  }
  .mask-view{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 100px;
    .content-view{
      display: flex;
      height: 550px;
      width: 1170px;
      justify-content: space-between;
    }
    .left-items{
      text-align: left;
      margin-right: 60px;
      .item{
        margin-top: 60px;
      }
      .item:first-child{
        margin-top: 0;
      }
      .name-view{
        position: relative;
        font-size: 24px;
        color: #2E3A6B;
        letter-spacing: 0;
        line-height: 40px;
        font-weight: bolder;
        z-index: 2;
        cursor: pointer;
        &::before{
          position: absolute;
          z-index: -1;
          content: '';
          bottom: 0;
          width: 230px;
          height: 20px;
          opacity: 0.2;
          background-image: linear-gradient(90deg, #77aaef00 0%, #689FEA 100%);
          border-radius: 10px;
        }
      }
      .name-view-active{
        &::before{
          opacity: 1;
        }
      }
      .desc{
        margin-top: 12px;
        font-size: 14px;
        color: #2E3A6B;
        line-height: 30px;
      }
      .tips{
        padding-left: 260px;
        margin-top: -60px;
        font-size: 14px;
        color: #2E3A6B;
        line-height: 30px;
      }
      .en-tips{
        font-size: 13px;
        line-height: 28px;
      }
    }
    .right-img{
      position: relative;
      .photo-img{
        height: 500px;
        display: block;
      }
      .next-img{
        cursor: pointer;
        position: absolute;
        transform: rotate(270deg);
        right: 40px;
        bottom: 20px;
        width: 55px;
        height: 55px
      }
    }
  }
}
</style>
