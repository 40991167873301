import i18n from "@/language/index"
import { computed } from 'vue'

const $COS = 'https://qtiao-web-1258997378.cos.ap-guangzhou.myqcloud.com/taoyuanintl/pc'

export const productProgress1 = computed(()=>{
  return [
    {
      label: i18n.global.t('productServices.card1.progress1Label'),
      tip: i18n.global.t('productServices.card1.progress1Tip'),
      progressList: [
        i18n.global.t('productServices.card1.progress1Desc1'),
        i18n.global.t('productServices.card1.progress1Desc2'),
      ]
    },
    {
      label: i18n.global.t('productServices.card1.progress2Label'),
      tip: i18n.global.t('productServices.card1.progress2Tip'),
      progressList: [
        i18n.global.t('productServices.card1.progress2Desc1'),
        i18n.global.t('productServices.card1.progress2Desc2'),
        i18n.global.t('productServices.card1.progress2Desc3'),
      ]
    },
    {
      label: i18n.global.t('productServices.card1.progress3Label'),
      tip: i18n.global.t('productServices.card1.progress3Tip'),
      progressList: [
      i18n.global.t('productServices.card1.progress3Desc1'),
      i18n.global.t('productServices.card1.progress3Desc2'),
      ]
    },
    {
      label: i18n.global.t('productServices.card1.progress4Label'),
      tip: i18n.global.t('productServices.card1.progress4Tip'),
      progressList: [
      i18n.global.t('productServices.card1.progress4Desc1'),
      i18n.global.t('productServices.card1.progress4Desc2'),
      ]
    },
    {
      label: i18n.global.t('productServices.card1.progress5Label'),
      tip: i18n.global.t('productServices.card1.progress5Tip'),
      progressList: [
        i18n.global.t('productServices.card1.progress5Desc1'),
        i18n.global.t('productServices.card1.progress5Desc2'),
        i18n.global.t('productServices.card1.progress5Desc3'),
      ]
    },
    {
      label: i18n.global.t('productServices.card1.progress6Label'),
      tip: i18n.global.t('productServices.card1.progress6Tip'),
      progressList: [
        i18n.global.t('productServices.card1.progress6Desc1'),
        i18n.global.t('productServices.card1.progress6Desc2'),
        i18n.global.t('productServices.card1.progress6Desc3'),
      ]
    },
    {
      label: i18n.global.t('productServices.card1.progress7Label'),
      tip: i18n.global.t('productServices.card1.progress7Tip'),
      progressList: [
        i18n.global.t('productServices.card1.progress7Desc1'),
        i18n.global.t('productServices.card1.progress7Desc2'),
      ]
    }
  ]
})
export const productProgress2 = computed(() => {
  return [
    {
      label: i18n.global.t('productServices.card2.progress1Label'),
      tip: i18n.global.t('productServices.card2.progress1Tip'),
    },
    {
      label: i18n.global.t('productServices.card2.progress2Label'),
      tip: i18n.global.t('productServices.card2.progress2Tip'),

    },
    {
      label: i18n.global.t('productServices.card2.progress3Label'),
      tip: i18n.global.t('productServices.card2.progress3Tip'),
    },
    {
      label: i18n.global.t('productServices.card2.progress4Label'),
      tip: i18n.global.t('productServices.card2.progress4Tip'),
    },
    {
      label: i18n.global.t('productServices.card2.progress5Label'),
      tip: i18n.global.t('productServices.card2.progress5Tip'),
    },
    {
      label: i18n.global.t('productServices.card2.progress6Label'),
      tip: i18n.global.t('productServices.card2.progress6Tip'),
    },
    {
      label: i18n.global.t('productServices.card2.progress7Label'),
      tip: i18n.global.t('productServices.card2.progress7Tip'),
    }
  ]
})
export const productProgress3 = computed(() => {
  return [
    {
      label: i18n.global.t('productServices.card3.progress1Label'),
      tip: i18n.global.t('productServices.card3.progress1Desc'),
    },
    {
      label: i18n.global.t('productServices.card3.progress2Label'),
      tip: i18n.global.t('productServices.card3.progress2Desc'),
    },
    {
      label: i18n.global.t('productServices.card3.progress3Label'),
      tip: i18n.global.t('productServices.card3.progress3Desc'),
    },
    {
      label: i18n.global.t('productServices.card3.progress4Label'),
      tip: i18n.global.t('productServices.card3.progress4Desc'),
    },
    {
      label: i18n.global.t('productServices.card3.progress5Label'),
      tip: i18n.global.t('productServices.card3.progress5Desc'),
    }
  ]
})
export const productProgress4 = computed(() => {
  return [
    {
      label: i18n.global.t('productServices.card4.progress1Label'),
      tip: i18n.global.t('productServices.card4.progress1Tip'),
    },
    {
      label: i18n.global.t('productServices.card4.progress2Label'),
      tip: i18n.global.t('productServices.card4.progress2Tip'),

    },
    {
      label: i18n.global.t('productServices.card4.progress3Label'),
      tip: i18n.global.t('productServices.card4.progress3Tip'),
    },
    {
      label: i18n.global.t('productServices.card4.progress4Label'),
      tip: i18n.global.t('productServices.card4.progress4Tip'),
    },
    {
      label: i18n.global.t('productServices.card4.progress5Label'),
      tip: i18n.global.t('productServices.card4.progress5Tip'),
    },
    {
      label: i18n.global.t('productServices.card4.progress6Label'),
      tip: i18n.global.t('productServices.card4.progress6Tip'),
    },
    {
      label: i18n.global.t('productServices.card4.progress7Label'),
      tip: i18n.global.t('productServices.card4.progress7Tip'),
    }
  ]
})

export const productProgress5 = computed(() => {
  return [
    {
      label: i18n.global.t('productServices.card5.progress1Label'),
      tip: i18n.global.t('productServices.card5.progress1Tip'),
    },
    {
      label: i18n.global.t('productServices.card5.progress2Label'),
      tip: i18n.global.t('productServices.card5.progress2Tip'),

    },
    {
      label: i18n.global.t('productServices.card5.progress3Label'),
      tip: i18n.global.t('productServices.card5.progress3Tip'),
    },
    {
      label: i18n.global.t('productServices.card5.progress4Label'),
      tip: i18n.global.t('productServices.card5.progress4Tip'),
    },
    {
      label: i18n.global.t('productServices.card5.progress5Label'),
      tip: i18n.global.t('productServices.card5.progress5Tip'),
    }
  ]
})

export const productProgress6 = computed(() => {
  return [
    {
      label: i18n.global.t('productServices.card6.progress1Label'),
      tip: i18n.global.t('productServices.card6.progress1Tip'),
    },
    {
      label: i18n.global.t('productServices.card6.progress2Label'),
      tip: i18n.global.t('productServices.card6.progress2Tip'),

    },
    {
      label: i18n.global.t('productServices.card6.progress3Label'),
      tip: i18n.global.t('productServices.card6.progress3Tip'),
    },
    {
      label: i18n.global.t('productServices.card6.progress4Label'),
      tip: i18n.global.t('productServices.card6.progress4Tip'),
    },
    {
      label: i18n.global.t('productServices.card6.progress5Label'),
      tip: i18n.global.t('productServices.card6.progress5Tip'),
    },
    {
      label: i18n.global.t('productServices.card6.progress6Label'),
      tip: i18n.global.t('productServices.card6.progress6Tip'),
    },
    {
      label: i18n.global.t('productServices.card6.progress7Label'),
      tip: i18n.global.t('productServices.card6.progress7Tip'),
    }
  ]
})

export const productProgress7 = computed(() => {
  return [
    {
      label: i18n.global.t('productServices.card7.progress1Label'),
      tip: i18n.global.t('productServices.card7.progress1Tip'),
    },
    {
      label: i18n.global.t('productServices.card7.progress2Label'),
      tip: i18n.global.t('productServices.card7.progress2Tip'),

    },
    {
      label: i18n.global.t('productServices.card7.progress3Label'),
      tip: i18n.global.t('productServices.card7.progress3Tip'),
    },
    {
      label: i18n.global.t('productServices.card7.progress4Label'),
      tip: i18n.global.t('productServices.card7.progress4Tip'),
    },
    {
      label: i18n.global.t('productServices.card7.progress5Label'),
      tip: i18n.global.t('productServices.card7.progress5Tip'),
    }
  ]
})


export const manageTeams = computed(() => {
  return [{
    name: i18n.global.t('manageTeam.team1.name'),
    desc: i18n.global.t('manageTeam.team1.position'),
    imgSrc_zh: `${$COS}/photo_1.png`,
    imgSrc_en: `${$COS}/en_photo_1.png`,
    tips: [
      i18n.global.t('manageTeam.team1.desc1'),
      i18n.global.t('manageTeam.team1.desc2'),
      i18n.global.t('manageTeam.team1.desc3'),
      i18n.global.t('manageTeam.team1.desc4'),
      i18n.global.t('manageTeam.team1.desc5'),
      i18n.global.t('manageTeam.team1.desc6'),
    ]
  },
  {
    name: i18n.global.t('manageTeam.team4.name'),
    desc:  i18n.global.t('manageTeam.team4.position'),
    imgSrc_zh: `${$COS}/photo_4.png`,
    imgSrc_en: `${$COS}/en_photo_4.png`,
    tips: [
      i18n.global.t('manageTeam.team4.desc1'),
      i18n.global.t('manageTeam.team4.desc2'),
      i18n.global.t('manageTeam.team4.desc3'),
      i18n.global.t('manageTeam.team4.desc4'),
      i18n.global.t('manageTeam.team4.desc5'),
    ]
  },

  {
    name: i18n.global.t('manageTeam.team2.name'),
    desc:  i18n.global.t('manageTeam.team2.position'),
    imgSrc_zh: `${$COS}/photo_2.png`,
    imgSrc_en: `${$COS}/en_photo_2.png`,
    tips: [
      i18n.global.t('manageTeam.team2.desc1'),
      i18n.global.t('manageTeam.team2.desc2'),
      i18n.global.t('manageTeam.team2.desc3'),
      i18n.global.t('manageTeam.team2.desc4'),
      i18n.global.t('manageTeam.team2.desc5'),
      i18n.global.t('manageTeam.team2.desc6'),
      i18n.global.t('manageTeam.team2.desc7'),
      i18n.global.t('manageTeam.team2.desc8'),
    ]
  },
  {
    name: i18n.global.t('manageTeam.team3.name'),
    desc:  i18n.global.t('manageTeam.team3.position'),
    imgSrc_zh: `${$COS}/photo_3.png`,
    imgSrc_en: `${$COS}/en_photo_3.png`,
    tips: [
      i18n.global.t('manageTeam.team3.desc1'),
      i18n.global.t('manageTeam.team3.desc2'),
      i18n.global.t('manageTeam.team3.desc3'),
      i18n.global.t('manageTeam.team3.desc4'),
      i18n.global.t('manageTeam.team3.desc5'),
    ]
  }]
})

