<template>
  <div class="slide-one-page">
    <div class="video_cover"></div>
    <video
      class="background_video"
      autoplay loop muted preload="auto">
      <source :src="`${$COS}/pc_video.mp4`" type="video/mp4">
      <p>Your browser doesn't support HTML5 video.</p>
    </video>
    <div class="mask-view">
      <div class="content-view">
        <div class="info-view">
          <img class="logo-img" loading="lazy" :src="`${$COS}/index_logo.png`">
          <div>
            <div class="desc">
              <p>{{$t("video.desc1")}}</p>
              <p>{{$t("video.desc1_1")}}</p>
              <p>{{$t("video.desc2")}}</p>
              <p>{{$t("video.desc2_2")}}</p>
              <p>{{$t("video.desc3")}}</p>
            </div>
            <div class="next-view">
              <div class="next-btn">
                <p>{{$t("video.desc4")}}</p>
                <img class="next-img" loading="lazy" :src="`${$COS}/img_scroll.png`" alt="" srcset="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="left-view-bg">
      <div class="left-view">
        <div class="tip">
          <p class="border"></p>
          <p>{{$t("video.title")}}</p>
        </div>
        <img class="menu-img" loading="lazy" :src="`${$COS}/menu_white.png`">
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data () {
    return {

    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.slide-one-page{
  width: 100%;
  height: 100%;
  position: relative;
  pointer-events: auto;
  .video_cover{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: url('https://qtiao-web-1258997378.cos.ap-guangzhou.myqcloud.com/taoyuanintl/pc/bg_cover.png') no-repeat center / cover;
  }
  .background_video{
    object-fit: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .mask-view{
    position: absolute;
    left: 0;
    top: 50px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    .content-view{
      height: 600px;
      width: 100%;
      display: flex;
      .info-view{
        flex: 1;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        .logo-img{
          height: 100px;
          display: block;
        }
        .desc{
          font-size: 20px;
          color: #FFFFFF;
          text-align: center;
          line-height: 40px;
        }
      }
    }
  }
  .next-view{
    margin-top: 40px;
    display: flex;
    justify-content: center;
    .next-btn{
      font-size: 20px;
      color: #FFFFFF;
      text-align: center;
      line-height: 40px;
      .next-img{
        height: 12px;
      }
    }
  }
  .left-view-bg{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    .left-view{
      position: relative;
      .tip{
        position: absolute;
        left: 95px;
        top: 75px;
        font-size: 20px;
        color: #FFFFFF;
        line-height: 40px;
        font-weight: bolder;
        display: flex;
        width: 120px;
        align-items: center;
        white-space: nowrap;
        .border{
          width: 12px;
          height: 12px;
          background-color: #FFF;
          border-radius: 50%;
          margin-right: 20px;
          box-shadow: 0 0 8px 0 #ffffff;
        }
      }
      .menu-img{
        height: 500px;
        display: block;
      }
    }
  }
  
}
</style>
