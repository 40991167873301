import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from "@/language/index"


const app = createApp(App)

app.config.globalProperties.$COS = 'https://qtiao-web-1258997378.cos.ap-guangzhou.myqcloud.com/taoyuanintl/pc'

// 移动端COS地址 部分文件共用
app.config.globalProperties.$COSM = 'https://qtiao-web-1258997378.cos.ap-guangzhou.myqcloud.com/taoyuanintl/mobile'




app.use(i18n).use(router).mount('#app')
