import { createI18n } from 'vue-i18n'
import en from './en';
import zh from './zh';

// 组合语言包对象
const messages = {
  en,
  zh
}

const i18n = createI18n({
  // something vue-i18n options here ...
  legacy: false,  // 设置为 false，启用 composition API 模式
  messages,
  locale: 'zh'
})

export default i18n