<template>
  <div class="slide-six-page">
    <div class="left-view-bg">
      <div class="left-view">
        <div class="tip">
          <p class="border"></p>
          <p>{{$t("contactUs.title")}}</p>
        </div>
        <img class="menu-img" :src="`${$COS}/menu_white.png`">
      </div>
    </div>
    <div class="bottom-record-bg">
      <div class="bottom-record">
        <p>{{copyright}}</p>
        <p class="divider-v"></p>
        <p>深圳市桃原国际管理咨询有限公司</p>
        <p class="divider-v"></p>
        <p>All Rights Reserved.</p>
        <p class="divider-v"></p>
        <p>
          <a class="gov" href="https://beian.miit.gov.cn" target="_blank">粤ICP备19066027号-4</a>
        </p>
      </div>
    </div>
    <div class="mask-view">
      <div class="content-view">
        <div class="top-items">
          <div class="contact-item">
            <p class="title">{{$t('contactUs.baseInfo.label1')}}</p>
            <p class="desc">0755-23224065</p>
          </div>
          <div class="contact-item">
            <p class="title">{{$t('contactUs.baseInfo.label2')}}</p>
            <p class="desc">yangguojiang@7tao.net</p>
          </div>
          <div class="contact-item">
            <p class="title">{{$t('contactUs.baseInfo.label3')}}</p>
            <p class="desc">hr@7tao.net</p>
          </div>
        </div>
        <div class="bottom-items">

          <div class="contact-item qr-item">
            <div>
              <p class="title">{{$t('contactUs.baseInfo.label6')}}</p>
              <p class="desc">{{$t('contactUs.baseInfo.desc6')}}</p>
            </div>
            <img class="qr-img" :src="`${$COS}/img_qrc@3x.png`">
          </div>

          <div class="contact-item">
            <p class="title">{{$t('contactUs.baseInfo.label4')}}</p>
            <p class="desc">{{$t('contactUs.baseInfo.desc4')}}</p>
            <p class="desc">{{$t('contactUs.baseInfo.desc4_4')}}</p>
          </div>
          <div class="contact-item">
            <p class="title">{{$t('contactUs.baseInfo.label5')}}</p>
            <p class="desc cur" @click="openImg">{{$t('contactUs.baseInfo.desc5')}}</p>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>


<script>
export default {
  data () {
    return {
    }
  },
  computed: {
    copyright () {
      return `Copyright © ${new Date().getFullYear()}.`
    }
  },
  methods: {
    openImg() {
      var imageUrl = "https://qtiao-web-1258997378.cos.ap-guangzhou.myqcloud.com/taoyuanintl/pc/license.png"; // 要打开的图片链接
      window.open(imageUrl);
    }
  }
}
</script>

<style scoped lang="scss">
.slide-six-page{
  width: 100%;
  height: 100%;
  position: relative;
  background: url('https://qtiao-web-1258997378.cos.ap-guangzhou.myqcloud.com/taoyuanintl/pc/bg_contact.png');
  background-size: 100% 100%;
  display: flex;
  .left-view-bg{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    .left-view{
      position: relative;
      .tip{
        position: absolute;
        left: 95px;
        bottom: 75px;
        font-size: 20px;
        color: #FFFFFF;
        line-height: 40px;
        font-weight: bolder;
        display: flex;
        align-items: center;
        white-space: nowrap;
        .border{
          width: 12px;
          height: 12px;
          background-color: #FFF;
          border-radius: 50%;
          margin-right: 20px;
          box-shadow: 0 0 8px 0 #ffffff;
        }
      }
      .menu-img{
        height: 500px;
        display: block;
      }
    }
  }
  .bottom-record-bg{
    position: absolute;
    bottom: 0;
    width: 100%;
    .bottom-record{
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #FFFFFF;
      padding: 30px 0;
      line-height: 20px;
      .divider-v{
        height: 14px;
        width: 1px;
        margin: 0 20px;
        background-color: #DDDDDD;
      }
      .gov{
        color: #FFFFFF;
        text-decoration: none;
      }
    }
  }

  .mask-view{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 310px;
    .content-view{
      padding-top: 20vh;
      .top-items, .bottom-items{
        display: flex;
        justify-content: space-between;
        .contact-item{
          border-top: 1px solid #FFF;
          padding-top: 13px;
          text-align: left;
          width: 350px;
          margin-right: 100px;
          .title{
            font-size: 24px;
            color: #FFFFFF;
            line-height: 40px;
          }
          .desc{
            font-size: 16px;
            color: #FFFFFF;
            line-height: 30px;
          }
        }
        .qr-item{
          display: flex;
          justify-content: space-between;
          .qr-img{
            width: 60px;
            height: 60px;
            display: block;
          }
        }
        .contact-item:last-child{
          margin-right: 0;
        }
        .cur{
          cursor: pointer;
        }
      }
      .bottom-items{
        margin-top: 80px;
        justify-content: flex-start;
      }
    }
  }

}
</style>
