<template>
  <div class="slide-two-page">
    <img class="bg-img" loading="lazy" :src="`${$COS}/bg_photo.png`">
    <img class="bg-img" loading="lazy" :src="`${$COS}/bg_mask.png`">
    <div class="mask-view">
      <div class="content-view">
        <div class="info-view">
          <p>{{$t("companyAbout.desc")}}</p>
        </div>
        <div class="video-view">
          <div class="video-content">
            <video
              controls
              class="video-js"
              loop
              autoplay
              :poster="poster"
              width="720"
              muted>
              <source :src="src" type="video/mp4" />
              <p>Your browser doesn't support HTML5 video.</p>
            </video>
            <div class="left-top-line"></div>
            <div class="right-bottom-line"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="left-view-bg">
      <div class="left-view">
        <div class="tip">
          <p class="border"></p>
          <p class="title">{{$t("companyAbout.title")}}</p>
        </div>
        <img class="menu-img" loading="lazy" :src="`${$COS}/menu_white.png`">
      </div>
    </div>
  </div>
</template>


<script>

export default {
  data () {
    return {
      src: `${this.$COSM}/ty-introduce.mp4`,
      poster: `${this.$COS}/img_video.mp4`,
    }
  },
  methods: {

  },
  mounted () {
  }
}
</script>

<style scoped lang="scss">
.slide-two-page{
  width: 100%;
  height: 100%;
  position: relative;
  // background: url('https://qtiao-web-1258997378.cos.ap-guangzhou.myqcloud.com/taoyuanintl/pc/bg_photo.png');
  // background-size: 100% 100%;
  .bg-img{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
  .mask-view{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    .content-view{
      height: 500px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      .info-view{
        text-align: left;
        margin-left: 310px;
        width: 500px;
        font-size: 20px;
        color: #FFFFFF;
        line-height: 40px;
        padding-top: 135px;
      }
      .video-view{
        padding-right: 128px;
        padding-top: 60px;
        .video-content{
          position: relative;
          .video-js{
            display: block;
            width: 720px;
          }
          .left-top-line{
            position: absolute;
            top: 15px;
            left: -35px;
            width: 105px;
            height: 2px;
            background-color: #FFF;
            transform: rotateZ(-45deg);
          }
          .right-bottom-line{
            position: absolute;
            bottom: 15px;
            right: -35px;
            height: 2px;
            width: 105px;
            z-index: 10;
            background-color: #FFF;
            transform: rotateZ(-45deg);
          }
        }
        .video-content:after{
          content: '';
          position: absolute;
          left: 8px;
          bottom: -8px;
          width: 100%;
          height: 8px;
          background: #689FEA;
        }
        .video-content:before{
          content: '';
          position: absolute;
          right: -8px;
          top: 8px;
          height: 100%;
          width: 8px;
          background: #689FEA;
        }
      }
    }
  }
  .left-view-bg{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    .left-view{
      position: relative;
      .tip{
        position: absolute;
        left: 95px;
        top: 137px;
        height: 40px;
        font-size: 20px;
        color: #FFFFFF;
        line-height: 40px;
        font-weight: bolder;
        display: flex;
        align-items: center;
        white-space: nowrap;
        .border{
          width: 12px;
          height: 12px;
          background-color: #FFF;
          border-radius: 50%;
          margin-right: 20px;
          box-shadow: 0 0 8px 0 #ffffff;
        }
        .title{
          width: 120px;
          height: 40px;
          white-space: break-spaces;
          text-align: left;
        }
      }
      .menu-img{
        height: 500px;
        display: block;
      }
    }
  }
}
</style>
